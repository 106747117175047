import { IUserTokenData } from './user.model';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

export interface ICandidate {
    userId: number;
    phone: string;
    phonePrefix: string;
    city: string;
    email: string;
    firstName: string;
    lastName: string;
    hasConfirmedEmail: boolean;
    username: string;
    token: IUserTokenData;
    status: ECandidateStatus;
    testRetryUrl: string | null;
    testRetryWaitForAdmin: boolean;
    media?: IMedia[];
    // survey answers
    cooperationForm: string[];
    licenses: string[];
    position: number | string;
    workPermit: number;
    workShift: string[];
    workingTime: string[];
}

export enum ECandidateStatus {
    notVerified,
    verifiedMissingSurvey,
    hasSurveyMissingTest,
    hasSurveyPendingTest,
    hasTestMissingApplication,
    hasAll,
}
