// extracted by mini-css-extract-plugin
export var accessibility = "header-user-module--accessibility--e7ec9";
export var actions = "header-user-module--actions--dad33";
export var backgroundAnimation = "header-user-module--backgroundAnimation--0cd1a";
export var button = "header-user-module--button--dea4b";
export var clientImage = "header-user-module--client-image--1f03b";
export var companyName = "header-user-module--company-name--70ef6";
export var container = "header-user-module--container--97ed3";
export var grid = "header-user-module--grid--1e007";
export var languageBox = "header-user-module--language-box--d24ce";
export var logos = "header-user-module--logos--05273";
export var logoutButton = "header-user-module--logout-button--849d0";
export var logoutIcon = "header-user-module--logout-icon--0c85e";
export var logoutText = "header-user-module--logout-text--ef08d";
export var navigationBox = "header-user-module--navigation-box--75a80";
export var sectionBox = "header-user-module--section-box--b1e5c";
export var separator = "header-user-module--separator--52118";
export var topBox = "header-user-module--top-box--58656";