import React, { useRef, useState } from 'react';
import { FreeMode } from 'swiper';

import {
    navigation,
    link as linkElement,
    active,
    button,
    secondaryItem,
    other,
    dropdown,
} from './panel-navigation.module.scss';
import { INavLink } from '../../models/nav-link.model';
import { isBrowser } from '../../utils/is-browser';

import Slider from '../hoc/slider';
import IconFactory from '../hoc/icon-factory';
import Dropdown from '../hoc/dropdown';
import ConditionalLink from '../hoc/conditional-link';

interface IPanelNavigationProps {
    className?: string;
    menu?: INavLink[];
}

const PanelNavigation: React.FC<IPanelNavigationProps> = ({ className = '', menu }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleToggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    if (!menu || !menu.length) return null;

    return (
        <nav className={`${className} ${navigation}`}>
            {menu[0] && menu[0].links && (
                <Slider
                    slidesPerView="auto"
                    freeMode
                    modules={[FreeMode]}
                    initialSlide={getInitialSlideIndex(menu[0].links)}
                >
                    {menu[0].links.map((link, i) => {
                        return (
                            <li key={`navigation-item-${i}`}>
                                <ConditionalLink
                                    type={link.type}
                                    blank={link.openInNewTabFlag}
                                    to={link.value || ''}
                                    activeClassName={active}
                                    className={linkElement}
                                    partiallyActive={true}
                                >
                                    {link.label}
                                </ConditionalLink>
                            </li>
                        );
                    })}
                </Slider>
            )}

            {menu[1] && menu[1].links && (
                <div className={other}>
                    <button ref={buttonRef} className={button} onClick={handleToggleDropdown}>
                        <IconFactory icon="more" />
                    </button>
                    <Dropdown
                        className={dropdown}
                        isOpen={isDropdownOpen}
                        closeFunction={handleCloseDropdown}
                        buttonRef={buttonRef}
                    >
                        <ul>
                            {menu[1].links.map((link, i) => {
                                return (
                                    <li
                                        className={secondaryItem}
                                        key={`navigation-item-secondary-${i}`}
                                    >
                                        <ConditionalLink
                                            type={link.type}
                                            blank={link.openInNewTabFlag}
                                            to={link.value || ''}
                                            activeClassName={active}
                                            partiallyActive={true}
                                        >
                                            {link.label}
                                        </ConditionalLink>
                                    </li>
                                );
                            })}
                        </ul>
                    </Dropdown>
                </div>
            )}
        </nav>
    );
};

function getInitialSlideIndex(links: INavLink[] | undefined) {
    if (!isBrowser() || !links) return 0;
    const pathname = window.location.pathname;
    const index = links.findIndex((link) => link.value && pathname.includes(link.value));
    return index > -1 ? index : 0;
}

export default PanelNavigation;
