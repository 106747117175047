import React, { useState } from 'react';
import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';

import {
    container,
    grid,
    sectionBox,
    topBox,
    actions,
    separator,
    languageBox,
    navigationBox,
    logoutButton,
    logoutText,
    logoutIcon,
    logos,
    companyName,
    clientImage,
    button,
    accessibility,
} from './header-user.module.scss';
import { ILogo } from '../../models/logo.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { relations } from '../../config/relations';
import { useCandidate } from '../../hooks/use-candidate';
import { useClient } from '../../hooks/use-client';
import useTranslations from '../../hooks/use-translations';

import Logo from '../atoms/logo';
import Section from '../hoc/section';
import LanguageSelector from '../molecules/language-selector';
import AsideMenu from '../organisms/aside-menu';
import IconFactory from '../hoc/icon-factory';
import IconButton from '../atoms/icon-button';
import PanelNavigation from '../molecules/panel-navigation';
import AccessibilityMenu from '../organisms/accessibility-menu';

export interface IHeaderUserSection extends ISection {
    items: {
        logo: ILogo | null;
        menuLinks: INavLink[];
        sideMenuLinks: INavLink[];
    };
}

export interface IHeaderUserProps {
    className?: string;
    section: IHeaderUserSection;
    type: 'candidate' | 'client';
}

const HeaderUser: React.FC<IHeaderUserProps> = ({ className = '', section, type }) => {
    const {
        style,
        items: { logo, menuLinks, sideMenuLinks },
        css,
        sectionId,
    } = section;
    const t = useTranslations('HeaderUser');
    const candidate = useCandidate();
    const client = useClient();

    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [isAccessibilityMenuOpen, setIsAccessibilityMenuOpen] = useState(false);
    const [isAccessibilityApplied, setIsAccessibilityApplied] = useState(false);

    const clientImageUrl =
        client.data?.media &&
        getMediaByRelation({ media: client.data.media, relation: relations.mainImage })?.url;

    const handleOpenSideMenu = () => {
        setIsSideMenuOpen(true);
    };

    const handleCloseSideMenu = () => {
        setIsSideMenuOpen(false);
    };

    const handleAccessibilityButtonClick = () => {
        setIsAccessibilityMenuOpen((state) => !state);
    };

    const closeAccessibilityMenu = () => {
        setIsAccessibilityMenuOpen(false);
    };

    const handleLogout = () => {
        if (type === 'candidate') {
            candidate.logout.fetch();
        }
        if (type === 'client') {
            client.logout.fetch();
        }
    };

    const handleAnyAccessibilityOptionApplied = (isAnyApplied: boolean) => {
        setIsAccessibilityApplied(isAnyApplied);
    };

    return (
        <Section
            Tag="header"
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <div className={topBox}>
                    <div className={logos}>
                        <div>{logo && <Logo logo={logo} />}</div>
                        {client.data && (
                            <>
                                <p>{t.for}</p>
                                {clientImageUrl ? (
                                    <img
                                        className={clientImage}
                                        src={clientImageUrl}
                                        alt={client.data.name}
                                    />
                                ) : (
                                    <p className={companyName}>{client.data.name}</p>
                                )}
                            </>
                        )}
                    </div>
                    <div className={actions}>
                        <button className={logoutButton} onClick={handleLogout}>
                            <span className={logoutText}>{t.logout}</span>
                            <IconFactory icon="logout" className={logoutIcon} />
                        </button>
                        <div className={languageBox}>
                            <LanguageSelector />
                        </div>
                        <IconButton
                            className={`${button} ${isAccessibilityApplied ? accessibility : ''}`}
                            onClick={handleAccessibilityButtonClick}
                        >
                            <span>AAaa</span>
                        </IconButton>
                        <span className={separator} />
                        <IconButton onClick={handleOpenSideMenu} title={'Menu'}>
                            <IconFactory icon="menu" />
                        </IconButton>
                    </div>
                </div>
                <div className={navigationBox}>
                    <PanelNavigation menu={menuLinks} />
                </div>
            </div>
            <AsideMenu
                isOpen={isSideMenuOpen}
                generalLinks={sideMenuLinks}
                closeFunction={handleCloseSideMenu}
            />
            <AccessibilityMenu
                isOpen={isAccessibilityMenuOpen}
                closeFunction={closeAccessibilityMenu}
                onAnyOptionApplied={handleAnyAccessibilityOptionApplied}
            />
        </Section>
    );
};

export default HeaderUser;
