import * as Yup from 'yup';
import { FormikProps } from 'formik';

import IFormField from '../models/form-field.model';
import { IConsent } from '../models/consent';
import { IOption } from '../models/option.model';
import { ICandidate } from '../models/candidate.model';
import { getConsentsInitialValues, getConsentsValidationSchema } from './consents.form';
import useTranslations from '../hooks/use-translations';

export type TCandidateDataFormType = 'edit' | 'register';

export interface ICandidateDataValues {
    email: string;
    firstName: string;
    lastName: string;
    password?: string;
    city: string;
    phonePrefix: string;
    phone: string;
}

export const candidateConsents = [
    {
        name: 'consentMarketing',
        required: false,
    },
    {
        name: 'consentRodo',
        required: true,
    },
    {
        name: 'consentRegulationsAndPrivacyPolicy',
        required: true,
    },
];

export const candidateConsentsData: (
    rodoContent: string,
    regulationsAndPrivacyContent: string
) => IConsent[] = (rodoContent: string, regulationsAndPrivacyContent: string) => {
    return [
        {
            name: 'consentMarketing',
            required: false,
        },
        {
            name: 'consentRodo',
            content: rodoContent,
            required: true,
        },
        {
            name: 'consentRegulationsAndPrivacyPolicy',
            content: regulationsAndPrivacyContent,
            required: true,
        },
    ];
};

const phonePrefixOptions: IOption[] = [
    {
        label: '+48',
        value: '48',
        imgUrl: '/images/svg/pl_flag.svg',
    },
    {
        label: '+380',
        value: '380',
        imgUrl: '/images/svg/uk_flag.svg',
    },
];

export function getCandidateDataInitialValues(
    formType: TCandidateDataFormType,
    candidate?: ICandidate | null
): ICandidateDataValues {
    if (formType === 'register' || !candidate) {
        return {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            city: '',
            phonePrefix: phonePrefixOptions[0].value.toString(),
            phone: '',
            ...getConsentsInitialValues(candidateConsents),
        };
    }
    return {
        email: candidate.email,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        city: candidate.city,
        phonePrefix: candidate.phonePrefix,
        phone: candidate.phone,
    };
}

export function getCandidateDataValidationSchema(
    t: ReturnType<typeof useTranslations<'CandidateDataForm'>>,
    tConsents: ReturnType<typeof useTranslations<'Consents'>>,
    formType: TCandidateDataFormType
) {
    return Yup.object({
        email: Yup.string().email(t.email.error.invalid).required(t.email.error.required),
        firstName: Yup.string().required(t.firstName.error.required),
        lastName: Yup.string().required(t.lastName.error.required),
        ...(formType === 'register'
            ? {
                  password: Yup.string()
                      .min(8, t.password.error.min)
                      .max(32, t.password.error.max)
                      .required(t.password.error.required),
              }
            : {}),
        city: Yup.string().required(t.city.error.required),
        phonePrefix: Yup.string().nullable().required(t.phonePrefix.error.required),
        phone: Yup.string().nullable().required(t.phone.error.required),
        ...(formType === 'register'
            ? getConsentsValidationSchema(tConsents, candidateConsents)
            : {}),
    });
}

export function getCandidateDataFields(
    t: ReturnType<typeof useTranslations<'CandidateDataForm'>>,
    formType: TCandidateDataFormType,
    formik?: FormikProps<ICandidateDataValues> | null
): IFormField[] {
    const fields: IFormField[] = [
        {
            name: 'email',
            type: 'email',
            placeholder: t.email.placeholder,
            hint: t.email.hint,
            isRequired: true,
            disabled: formType === 'edit',
        },
        {
            name: 'firstName',
            type: 'text',
            placeholder: t.firstName.placeholder,
            isRequired: true,
        },
        {
            name: 'lastName',
            type: 'text',
            placeholder: t.lastName.placeholder,
            isRequired: true,
        },
        {
            name: 'password',
            type: 'password',
            placeholder: t.password.placeholder,
            isRequired: true,
        },
        {
            name: 'city',
            type: 'text',
            placeholder: t.city.placeholder,
            isRequired: true,
        },
        {
            name: 'phonePrefix',
            type: 'select',
            isRequired: true,
            isClearable: false,
            options: phonePrefixOptions,
        },
        {
            name: 'phone',
            type: 'tel',
            placeholder: t.phone.placeholder,
            isRequired: true,
            mask: '999999999',
            prefix:
                (formik &&
                    phonePrefixOptions.find((option) => option.value === formik.values.phonePrefix)
                        ?.label) ||
                '',
        },
    ];

    return formType === 'register'
        ? fields
        : fields.filter((field) => field.name !== 'password' && field.type !== 'consents');
}
