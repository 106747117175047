import React from 'react';
import { Form, Formik, FormikConfig } from 'formik';

import {
    button,
    container,
    globalError,
    loading,
    requiredText,
} from './candidate-data-form.module.scss';

import { useCandidate } from '../../hooks/use-candidate';
import useTranslations from '../../hooks/use-translations';
import { useModal } from '../../hooks/use-modal';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useSharedFile } from '../../hooks/use-shared-file';
import { ESharedFileRelation } from '../../models/shared-file.model';
import {
    candidateConsentsData,
    getCandidateDataFields,
    getCandidateDataInitialValues,
    getCandidateDataValidationSchema,
    ICandidateDataValues,
    TCandidateDataFormType,
} from '../../formik/candidate-data.form';

import FieldGenerator from '../molecules/field-generator';
import Button from '../atoms/button';
import Consents from '../molecules/consents';

interface ICandidateDataFormProps {
    className?: string;
    type: TCandidateDataFormType;
    jobOfferId?: string | number;
}

const CandidateDataForm: React.FC<ICandidateDataFormProps> = ({
    className = '',
    type,
    jobOfferId,
}) => {
    const t = useTranslations('CandidateDataForm');
    const tConsents = useTranslations('Consents');
    const { addModal } = useModal();
    const candidate = useCandidate();
    const errors = type === 'register' ? candidate.register.errors : candidate.update.errors;
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(errors);
    const candidateRegulations = useSharedFile(ESharedFileRelation.candidateRegulations);
    const privacyPolicy = useSharedFile(ESharedFileRelation.privacyPolicy);

    const isLoading = candidate.register.isLoading || candidate.update.isLoading;

    const handleSubmit: FormikConfig<ICandidateDataValues>['onSubmit'] = async (values) => {
        if (type === 'register') {
            await candidate.register.fetch({
                ...values,
                ...(jobOfferId ? { advertisementId: jobOfferId } : {}),
            });
            return;
        }
        if (type === 'edit') {
            try {
                await candidate.update.fetch(values).unwrap();
                addModal({
                    modalKey: 'common-modal',
                    modalProps: {
                        title: t.updateSuccess.title,
                        confirmText: t.updateSuccess.confirm,
                        children: t.updateSuccess.content,
                        actions: ['confirm'],
                    },
                });
            } catch {}
        }
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getCandidateDataInitialValues(type, candidate.data)}
            validationSchema={getCandidateDataValidationSchema(t, tConsents, type)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {(formik) => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    {getCandidateDataFields(t, type, formik).map((field) => {
                        return (
                            <FieldGenerator
                                key={`candidate-data-field-${field.name}`}
                                field={field}
                            />
                        );
                    })}
                    {type === 'register' && (
                        <>
                            <p className={requiredText}>{t.required}</p>
                            <Consents
                                consents={candidateConsentsData(
                                    tConsents.consentRodo(privacyPolicy?.pathname || ''),
                                    tConsents.consentRegulationsAndPrivacyPolicy(
                                        privacyPolicy?.pathname || '',
                                        candidateRegulations?.pathname || ''
                                    )
                                )}
                                showConsentsDisclaimer={true}
                            />
                        </>
                    )}
                    {globalErrorMessage && <p className={globalError}>{globalErrorMessage}</p>}
                    <Button className={button} type="submit" isLoading={isLoading}>
                        {t.button[type]}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default CandidateDataForm;
