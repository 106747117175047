import { FormikProps } from 'formik';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

import {
    IError,
    errorsToObjectByField,
    scrollToFirstWrongField,
    setFormikErrors,
} from './get-form-errors';
import { getRtkQueryErrors } from './get-rtk-query-errors';

interface IRTKQueryErrorTranslations {
    FETCH_ERROR: string;
    PARSING_ERROR: string;
    TIMEOUT_ERROR: string;
    CUSTOM_ERROR: string;
}

export function handleFormRTKQueryErrors<Values extends Record<string, unknown>>(
    formik: FormikProps<Values> | null,
    error: FetchBaseQueryError | SerializedError | IError[] | undefined,
    translations: IRTKQueryErrorTranslations
) {
    if (!error) return;
    if (!formik) return;
    formik.setSubmitting(false);

    const errors = Array.isArray(error) ? error : getRtkQueryErrors(error, translations);

    if (!errors) return;

    const formKeys = Object.keys(formik.values);
    const errorsRelatedToForm = getErrorsRelatedToForm(formKeys, errors);
    const errorsNotRelatedToForm = getErrorsNotRelatedToForm(formKeys, errors);

    const formErrors = errorsToObjectByField(errorsRelatedToForm);

    scrollToFirstWrongField(formErrors);
    setFormikErrors(formik, formErrors);

    return { globalError: formErrors.global, otherErrors: errorsNotRelatedToForm };
}

export function getErrorsRelatedToForm(formKeys: string[], errors: IError[]) {
    return errors.filter((error) => {
        return formKeys.includes(error.field.split('.')[0]) || error.field === 'global';
    });
}

export function getErrorsNotRelatedToForm(formKeys: string[], errors: IError[]) {
    return errors.filter((error) => {
        return !formKeys.includes(error.field.split('.')[0]) && error.field !== 'global';
    });
}
