import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import { container, globalError, button, loading } from './change-password-form.module.scss';
import {
    getChangePasswordFields,
    getChangePasswordInitialValues,
    getChangePasswordValidationSchema,
    IChangePasswordValues,
} from '../../formik/change-password.form';
import useTranslations from '../../hooks/use-translations';
import { useModal } from '../../hooks/use-modal';
import { useClient } from '../../hooks/use-client';
import { useCandidate } from '../../hooks/use-candidate';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';

import FieldGenerator from '../molecules/field-generator';
import Button from '../atoms/button';

interface IChangePasswordFormProps {
    className?: string;
}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({ className = '' }) => {
    const t = useTranslations('ChangePasswordForm');
    const { addModal } = useModal();
    const client = useClient();
    const candidate = useCandidate();
    const errors = client.isLoggedIn
        ? client.changePassword.errors
        : candidate.changePassword.errors;
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(errors);

    const isLoading = client.changePassword.isLoading || candidate.changePassword.isLoading;

    const handleSubmit = async (
        values: IChangePasswordValues,
        helpers: FormikHelpers<IChangePasswordValues>
    ) => {
        try {
            if (client.isLoggedIn) {
                await client.changePassword.fetch(values).unwrap();
            }
            if (candidate.isLoggedIn) {
                await candidate.changePassword.fetch(values).unwrap();
            }
            addModal({
                modalKey: 'common-modal',
                modalProps: {
                    title: t.updateSuccess.title,
                    confirmText: t.updateSuccess.confirm,
                    children: t.updateSuccess.content,
                    actions: ['confirm'],
                },
            });
            helpers.resetForm();
        } catch {}
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getChangePasswordInitialValues()}
            validationSchema={getChangePasswordValidationSchema(t)}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    {getChangePasswordFields(t).map((field) => {
                        return (
                            <FieldGenerator
                                key={`change-password-field-${field.name}`}
                                field={field}
                            />
                        );
                    })}
                    {globalErrorMessage && <p className={globalError}>{globalErrorMessage}</p>}
                    <Button type="submit" className={button} isLoading={isLoading}>
                        {t.submit}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default ChangePasswordForm;
