import { ISection } from '../models/section.model';

export default function getPageConfigFromSections(sections: ISection[]) {
    let mainSectionIndex = sections.findIndex(({ settings }) => settings?.isMain);
    mainSectionIndex = mainSectionIndex === -1 ? 0 : mainSectionIndex;

    return {
        mainSectionIndex,
    };
}
