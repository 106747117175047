import * as Yup from 'yup';

import IFormField from '../models/form-field.model';
import useTranslations from '../hooks/use-translations';

export interface IChangePasswordValues {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
}

export function getChangePasswordInitialValues(): IChangePasswordValues {
    return {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
    };
}

export function getChangePasswordValidationSchema(
    t: ReturnType<typeof useTranslations<'ChangePasswordForm'>>
) {
    return Yup.object({
        currentPassword: Yup.string().required(t.currentPassword.error.required),
        newPassword: Yup.string()
            .min(8, t.newPassword.error.min)
            .max(32, t.newPassword.error.max)
            .required(t.newPassword.error.required),
        repeatPassword: Yup.string()
            .required(t.repeatPassword.error.required)
            .oneOf([Yup.ref('newPassword'), null], t.repeatPassword.error.match),
    });
}

export function getChangePasswordFields(
    t: ReturnType<typeof useTranslations<'ChangePasswordForm'>>
): IFormField[] {
    return [
        {
            name: 'currentPassword',
            type: 'password',
            placeholder: t.currentPassword.placeholder,
            isRequired: true,
        },
        {
            name: 'newPassword',
            type: 'password',
            placeholder: t.newPassword.placeholder,
            hint: t.newPassword.hint,
            isRequired: true,
        },
        {
            name: 'repeatPassword',
            type: 'password',
            placeholder: t.repeatPassword.placeholder,
            isRequired: true,
        },
    ];
}
