import { ISection } from '../models/section.model';

export default function getSectionsWithRepeatIndex(sections: ISection[]): ISection[] {
    let repeatIndex = 0;
    return sections.map((section, index) => {
        if (section.type === sections[index - 1]?.type) {
            repeatIndex += 1;
        } else {
            repeatIndex = 0;
        }
        return {
            ...section,
            config: {
                ...section.settings,
                repeatIndex,
            },
        };
    });
}
