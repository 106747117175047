import React from 'react';

import { container, border } from './radius-box.module.scss';

interface IRadiusBoxProps {
    className?: string;
    children?: React.ReactNode;
    hasBorder?: boolean;
}

const RadiusBox: React.FC<IRadiusBoxProps> = ({ className = '', children, hasBorder = true }) => {
    return <div className={`${container} ${className} ${hasBorder ? border : ''}`}>{children}</div>;
};

export default RadiusBox;
