import React from 'react';

import {
    container,
    grid,
    sectionBox,
    radiusBox,
    separator,
    titleText,
    form,
} from './candidate-data-change.module.scss';
import { ISection } from '../../models/section.model';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import CandidateDataForm from '../organisms/candidate-data-form';
import ChangePasswordForm from '../organisms/change-password-form';

export interface ICandidateDataChangeProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const CandidateDataChange: React.FC<ICandidateDataChangeProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const t = useTranslations('CandidateDataChange');
    const { style, css, sectionId } = section;

    const SecondaryTitleTag = TitleTag === 'h1' ? getLessImportantHeading(TitleTag) || 'p' : 'h2';

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <RadiusBox className={radiusBox}>
                    <TitleTag className={titleText}>{t.dataTitle}</TitleTag>
                    <span className={separator} />
                    <CandidateDataForm className={form} type="edit" />
                </RadiusBox>
                <RadiusBox className={radiusBox}>
                    <SecondaryTitleTag className={titleText}>{t.passwordTitle}</SecondaryTitleTag>
                    <span className={separator} />
                    <ChangePasswordForm className={form} />
                </RadiusBox>
            </div>
        </Section>
    );
};

export default CandidateDataChange;
