import * as Yup from 'yup';
import { AnySchema } from 'yup';

import { IConsent } from '../models/consent';
import useTranslations from '../hooks/use-translations';

export function getConsentsInitialValues(consents: IConsent[]) {
    return consents.reduce((acc, consent) => {
        acc[consent.name] = false;
        return acc;
    }, {} as Record<string, boolean>);
}

export function getConsentsValidationSchema(
    t: ReturnType<typeof useTranslations<'Consents'>>,
    consents: IConsent[]
) {
    return consents.reduce((acc, consent) => {
        acc[consent.name] = consent.required
            ? Yup.boolean().required(t.required).oneOf([true], t.required)
            : Yup.boolean();
        return acc;
    }, {} as Record<string, AnySchema>);
}
